* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #373737;
}

.login-beta-contain {
  background-color: #373737;
  width: 100%;
  height: 100vh;
  padding-top: 54px;
}

.login-beta-contain img {
  margin-left: 59px;
  height: 89px;
}

.nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.icon {
  margin-right: 34px;
  color: #ffffff;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 67% 33%;
  padding: 0 228px;
  margin-top: 470px;
  align-items: flex-start;
  justify-content: center;
}

.para1 {
  font-family: "Helvetica Neue LT bd Std";
  font-style: normal;
  font-weight: 700;
  font-size: 219px;
  line-height: 191px;
  display: flex;
  align-items: center;
  letter-spacing: -1.56px;
  color: #fd5d5d;
}

.para2 {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 61px;
  line-height: 62px;
  display: flex;
  align-items: center;
  letter-spacing: -0.48px;
  color: #f1f1ef;
}

.paragraph {
  width: 100%;
}

.paragraph2 {
  max-width: 1433px;
  margin-top: 48px;
}

.input1 {
  color: white;
  width: 100%;
  height: 86px;
  position: relative;
  background: #2b2a2a;
  border-radius: 5px;
  border: none;
}

.input1:focus {
  background: #2a2a2a;
  border: 1px solid #fd5d5d;
  border-radius: 5px;
}
.personalLinkagain {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 38px;
  /* identical to box height, or 162% */

  display: flex;
  align-items: center;

  color: #ffffff;
  margin-top: 26px;
}

.paras {
  margin-right: 45px;
}

/* 2nd page */
.nav button {
  background: #f5ff01;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 49px;
  gap: 10px;
  margin-right: 42px;
  border: none;
  font-family: "B612 Mono bd";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
}

.boxx {
  display: flex;
  max-width: 400px;
  background-color: #fd5d5d;
  max-height: 100px;
  align-items: flex-end;
  padding-right: 17px;
  padding-left: 5px;
  border-radius: 0px 0px 5px 5px;
  height: 75px;
}

.form2 {
  width: 100%;
  background-color: #000000;
  padding: 0px 56px;
  height: auto;
  border-radius: 15px;
  position: relative;
  bottom: 41px;
}

.place {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #000000;
}

.placeNumber {
  font-family: "Helvetica Neue LT bd Std";
  font-style: normal;
  font-weight: 700;
  font-size: 51px;
  line-height: 38px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #f1f1ef;
}

.queue {
  font-family: "Helvetica Neue LT bd Std";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #f1f1ef;
  margin-top: 43px;
}

.waitlist {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 41px;
  display: flex;
  align-items: center;
  color: #adadad;
  margin-top: 34px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.forlineBox2 {
  width: 100%;
  border: 2px solid #fd5d5d;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
}

.line p {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 47px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.forline {
  border: 1px solid #fd5d5d;
}

::placeholder {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #adadad;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 7px 9px;
}

/* .alone {
  margin-top: 55px;
} */

.Twitter {
  width: 200px;
  padding: 10px 26px;
  background: #f5ff01;
  border-radius: 2px;
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  display: flex;
  flex: 1;
  align-items: center;
  color: #000000;
  height: 86px;
  border: none;
  justify-content: center;
  cursor: pointer;
}

.linkedin {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #000000;
  border: none;
  cursor: pointer;
  width: 200px;
  height: 86px;
  left: 1459px;
  top: 726px;
  background: #f5ff01;
  border-radius: 2px;
  padding: 11px 17px;
  margin-left: 10px;
}

.shareButton {
  display: flex;
  margin-top: 25px;
  margin-bottom: 46px;
}

input:focus {
  outline: none !important;
}

.error {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  display: flex;
  align-items: center;
  color: #fd5d5d;
}

.iconform {
  display: block;
}

.crossicon {
  display: none;
}

.LinkBox2 {
  display: none;
}
.twitterMobile {
  margin-right: 8px;
}
.LinkedinMobile {
  margin-right: 8px;
}
@media only screen and (min-width: 1921px) and (max-width: 2560px) {
  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 67% 33%;
    padding: 0 228px;
    margin-top: 237px;

    align-items: flex-start;
    justify-content: center;
  }

  .para1 {
    font-size: 173px;
  }

  .para2 {
    font-size: 57px;
  }

  .paragraph {
    width: 100%;
  }

  .paragraph2 {
    max-width: 1433px;
    margin-top: 48px;
  }
  .line p {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 47px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .paras {
    margin-right: 45px;
  }

  /* 2nd page */
  .nav button {
    background: #f5ff01;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 49px;
    gap: 10px;
    margin-right: 42px;
    border: none;
    font-family: "B612 Mono bd";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    color: #000000;
    height: 51px;
  }

  .boxx {
    display: flex;
    max-width: 353px;
    background-color: #fd5d5d;
    max-height: 100px;
    align-items: flex-end;
    padding-right: 17px;
    padding-left: 5px;
    border-radius: 0px 0px 5px 5px;
    height: 75px;
  }

  .form2 {
    width: 100%;
    background-color: #000000;
    padding: 0px 56px;
    height: auto;
    border-radius: 15px;
    position: relative;
    bottom: 41px;
  }

  .place {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 31px;

    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 51px;
    line-height: 63px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
  }

  .queue {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
    margin-top: 43px;
  }

  .waitlist {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 41px;
    display: flex;
    align-items: center;
    color: #adadad;
    margin-top: 34px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .forlineBox2 {
    width: 100%;
    border: 2px solid #fd5d5d;
  }

  /* .alone {
    margin-top: 55px;
  } */

  .Twitter {
    width: 200px;
    padding: 10px 26px;
    background: #f5ff01;
    border-radius: 2px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex: 1;
    align-items: center;
    color: #000000;
    height: 86px;
    border: none;
    justify-content: center;
    cursor: pointer;
  }

  .linkedin {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #000000;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 86px;
    left: 1459px;
    top: 726px;
    background: #f5ff01;
    border-radius: 2px;
    padding: 11px 17px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1920px) {
  .login-beta-contain {
    background-color: #373737;
    width: 100%;
    height: 100vh;
    padding-top: 54px;
  }

  .login-beta-contain img {
    margin-left: 59px;
    height: 42.26px;
    width: 200px;
  }

  .nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .icon {
    margin-right: 34px;
    color: #ffffff;
  }

  .content {
    width: 100%;
    display: flex;
    padding: 0 228px;
    margin-top: 232px;
    align-items: flex-start;
    justify-content: center;
  }

  .para1 {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 128px;
    line-height: 110px;
    display: flex;
    align-items: center;
    letter-spacing: -1.56px;
    color: #fd5d5d;
  }

  .para2 {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: -0.48px;
    color: #f1f1ef;
  }

  .paragraph {
    max-width: 961px;
  }

  .paragraph2 {
    max-width: 884px;
    margin-top: 29px;
  }
  .personalLinkagain {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */

    display: flex;
    align-items: center;

    color: #ffffff;
    margin-top: 26px;
  }
  .input1 {
    color: white;
    width: 100%;
    height: 40px;
    position: relative;
    background: #484848;
    border-radius: 5px;
    border: none;
  }

  .input1:focus {
    background: #2a2a2a;
    border: 1px solid #fd5d5d;
    border-radius: 5px;
  }

  .paras {
    margin-right: 31px;
  }

  /* 2nd page */
  .nav button {
    background: #f5ff01;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 49px;
    gap: 10px;
    margin-right: 42px;
    border: none;
    font-family: "B612 Mono bd";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .boxx {
    display: flex;
    max-width: 317px;
    background-color: #fd5d5d;
    max-height: 65px;
    align-items: flex-end;
    padding-right: 17px;
    padding-left: 5px;
    border-radius: 0px 0px 5px 5px;
    height: 65px;
  }
  .forlineBox2 {
    width: 100%;
    border: 2px solid #fd5d5d;
  }

  .form2 {
    max-width: 472px;
    background-color: #000000;
    padding: 0px 31px;
    height: auto;
    border-radius: 15px;
    position: relative;
    bottom: 41px;
  }

  .place {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 40px;
    margin-left: 14px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
  }

  .queue {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
    margin-top: 15px;
  }

  .waitlist {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #adadad;
    margin-top: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .line {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
  }

  .line p {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .forline {
    border: 1px solid #fd5d5d;
  }

  .input1::placeholder {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    padding-left: 9px;
    margin-top: 7px;
  }

  /* .alone {
    margin-top: 55px;
  } */

  .Twitter {
    width: 200px;
    padding: 10px 15px;
    background: #f5ff01;
    border-radius: 2px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    height: 40px;
    border: none;
    cursor: pointer;
  }

  .linkedin {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    border: none;
    cursor: pointer;
    width: 200px;
    max-height: 40px;
    left: 1459px;
    top: 726px;
    background: #f5ff01;
    border-radius: 2px;
    padding: 11px 12px;
    margin-left: 10px;
  }

  .shareButton {
    display: flex;
    margin-top: 10px;
    margin-bottom: 36px;
  }

  input:focus {
    outline: none !important;
  }

  .error {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #fd5d5d;
  }

  .iconform {
    display: block;
  }

  .crossicon {
    display: none;
  }

  .LinkBox2 {
    display: none;
  }
  .twitterMobile {
    margin-right: 8px;
  }
  .LinkedinMobile {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 1499px) and (max-width: 1782px) {
  .content {
    margin-top: 161px;
  }
  .para1 {
    font-size: 92px;
    line-height: 77px;
  }
  .paragraph2 {
    max-width: 624px;
    margin-top: 11px;
  }
  .para2 {
    font-size: 27px;
  }
  .form2 {
    max-width: 429px;
  }
  .place {
    font-size: 13px;
  }
  .placeNumber {
    font-size: 40px;
  }
  .boxx {
    max-width: 282px;
  }
  .queue {
    font-size: 33px;
    margin-top: 8px;
  }
  .waitlist {
    font-size: 16px;
  }
  .line {
    margin-top: 12px;
  }
  .line p {
    font-size: 16px;
  }
  .personalLinkagain {
    margin-top: 12px;
  }
  .linkedin {
    font-size: 13px;
  }
  .Twitter {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1500px) {
  .error {
    font-size: 15px;
    line-height: 40px;
  }

  .para1 {
    font-size: 77px;
    line-height: 0.9;
  }

  .para2 {
    font-size: 21px;
    line-height: 1.1;
  }

  .content {
    margin-top: 150px;
  }

  .paragraph2 {
    max-width: 456px;
    margin-top: 20px;
  }

  ::placeholder {
    font-size: 10px;
    padding: 5px 7px;
  }

  .login-beta-contain {
    padding-top: 28px;
  }

  .input1 {
    height: 32px;
  }

  .par {
    font-size: 65px;
  }

  .par2 {
  }

  .parr {
    font-size: 20px;
    margin-top: 20px;
  }
  .forlineBox2 {
    width: 100%;
    border: 2px solid #fd5d5d;
  }
  .place {
    font-size: 6px;
    line-height: 14px;
  }

  .placeNumber {
    font-size: 18px;
    line-height: 18px;
    margin-left: 8px;
  }

  .boxx {
    max-width: 147px;
    height: 33px;
  }

  .queue {
    margin-top: 3px;
    font-size: 22px;
  }

  .waitlist {
    font-size: 12px;
    line-height: 1.2;
  }

  .form2 {
    max-width: 320px;
    padding: 0px 20px;
  }

  .line {
    margin-top: 5px;
  }

  .line p {
    font-size: 12px;
  }

  /* .alone {
    margin-top: 16px;
  } */

  .Twitter {
    /* padding: 10px 11px; */
    font-size: 9px;
  }

  .linkedin {
    font-size: 8px;
    /* padding: 1px 3px; */
  }

  .forline {
    border: 1px solid #fd5d5d;
  }

  .shareButton {
    margin-bottom: 18px;
  }

  .iconform {
    display: block;
  }

  .crossicon {
    display: none;
  }

  .LinkBox2 {
    display: none;
  }
  .twitterMobile {
    margin-right: 2px;
  }
  .LinkedinMobile {
    margin-right: 2px;
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .para1 {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 0.9;
    display: flex;
    justify-content: center;
    color: #fd5d5d;
  }

  .para2 {
    font-size: 16px;
    line-height: 1.1;
  }

  .paras {
  }

  .paragraph2 {
    max-width: 347px;
    margin-top: 20px;
  }

  .form2 {
    max-width: 320px;
    background-color: #000000;
    padding: 0px 31px;
    height: auto;
    margin-top: 40px;
  }

  .content {
    width: 100%;
    display: flex;
    padding: 0 228px;
    margin-top: 132px;
  }
  .forlineBox2 {
    width: 100%;
    border: 2px solid #fd5d5d;
  }

  .iconform {
    display: block;
  }

  .crossicon {
    display: none;
  }

  .LinkBox2 {
    display: none;
  }

  .Twitter {
    /* padding: 10px 11px; */
    font-size: 7px;
  }

  .linkedin {
    font-size: 7px;
    /* padding: 1px 3px; */
  }
}

@media only screen and (max-width: 1000px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 98px;
    margin-top: 132px;
  }

  .paras {
    text-align: center;
  }

  .paragraph2 {
    max-width: none;
  }

  .Twitter {
    padding: 10px 4px;
  }

  .login-beta-contain img {
    margin-left: 38px;
  }

  .linkedin {
    padding: 10px 4px;
  }

  .crossicon {
    display: none;
  }

  .LinkBox2 {
    display: none;
  }
  .forlineBox2 {
    width: 100%;
    border: 2px solid #fd5d5d;
  }

  .form2 {
    padding: 0px 15px;
    margin-top: 58px;
  }

  .login-beta-contain img {
    margin-left: 38px;
  }
}

@media screen and (max-width: 500px) {
  body {
    padding: 0;
    margin: 0;
  }

  .login-beta-contain {
    padding: 0;
    margin: 0;
  }

  .login-beta-contain img {
    margin: 20px 0px 0px 20px;
  }

  .icon {
    margin-top: 15.5px;
    margin-right: 19.5px;
  }

  .content {
    margin-top: 42.87px;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .nav img {
    width: 100px;
    height: 21.13px;
  }

  .para1 {
    display: none;
  }

  .paras {
    width: 100%;
    text-align: start;
    margin-right: 0px;
  }

  .paragraph {
    width: 100%;
  }

  .para2 {
    display: none;
  }

  .paragraph2 {
    margin: 0;
  }

  .input1 {
    position: relative;
    max-width: 300px;
    height: 50px;
    bottom: 13px;
    background: #2b2a2a;
    border-radius: 5px;
  }

  .login-beta-contain {
    height: auto;
  }

  .nav button {
    display: none;
  }

  .nav .icon {
    display: block;
  }

  .para2 {
    display: none;
  }

  .crossicon {
    display: block;
  }

  .par {
    display: block;
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 59px;

    letter-spacing: -1.56px;
    color: #fd5d5d;
    margin: 0px 15px 0px 20px;
  }

  .betapara {
    display: block;
  }

  .parr {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 32px;
    color: #ffffff;
    margin: 18px 26px 0px 20px;
  }

  .form2 {
    margin-bottom: 48px;
    max-width: 379px;
    padding: 0;
    position: relative;
    bottom: 0px;
  }

  .boxx {
    max-width: 225px;
    height: 45px;
    margin-left: 15px;
    padding-left: 6px;
    padding-right: 4px;
    padding-bottom: 3px;
  }

  .place {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 22px;
    display: flex;
    margin-left: 8px;
    align-items: center;
    color: #f1f1ef;
  }

  .queue {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
    margin-top: 21px;
    margin-left: 15px;
  }

  .waitlist {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 35px;
    color: #adadad;
    margin-left: 15px;
    margin-top: 5px;
  }

  .refferBox {
    display: none;
  }

  .refferBox2 {
    display: block;
    margin: 34px 15px 0px;
  }

  .referBox2 {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .forlineBox2 {
    width: 100%;
    border: 2px solid #fd5d5d;
  }
  .personalLinkagain {
    display: none;
  }

  .personalLink {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-top: 37px;
    margin-left: 15px;
    color: #ffffff;
  }

  .inputPersonalLink {
    box-sizing: border-box;
    width: 94%;
    height: 50px;
    border: none;
    margin-left: 15px;
    background: #2b2a2a;
    border-radius: 5px;
    color: white;
  }

  .linkinput {
    display: none;
  }

  .LinkBox2 {
    display: block;
  }

  .shareButton {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }

  .Twitter {
    width: 93%;
    margin-left: 17px;
    margin-right: 13px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #000000;
    justify-content: center;
  }

  .linkedin {
    box-sizing: border-box;
    width: 93%;
    height: 40px;
    margin-left: 17px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    justify-content: center;
    margin-top: 14px;
    background: #f5ff01;
    border-radius: 2px;
    padding: 10px 11px;
  }

  .refer {
    margin-top: 18px;
  }

  .line p {
    font-size: 15px;
  }

  .inputPersonalLink::placeholder {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #b8b9bb;
    padding-left: 16.53px;
  }
  .twitterMobile {
    margin-right: 8px;
  }
  .LinkedinMobile {
    margin-right: 8px;
  }
}
/* //////////////////////////////////////////////////////////////////////////////////////// */
@media screen and (max-width: 376px) {
  body {
    padding: 0;
    margin: 0;
  }

  .login-beta-contain {
    padding: 0;
    margin: 0;
  }

  .login-beta-contain img {
    margin: 20px 0px 0px 20px;
  }
  /* 
  .icon {
    margin-top: 22.5px;
    margin-right: 19.5px;
  } */

  .content {
    margin-top: 42.87px;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .para1 {
    display: none;
  }

  .paras {
    width: 100%;
    text-align: start;
    margin-right: 0px;
  }

  .paragraph {
    width: 100%;
  }

  .para2 {
    display: none;
  }
  .nav img {
    width: 100px;
    height: 21.13px;
  }

  .paragraph2 {
    margin: 0;
  }

  .input1 {
    position: relative;
    max-width: 300px;
    height: 50px;
    bottom: 13px;
    background: #2b2a2a;
    border-radius: 5px;
  }

  .login-beta-contain {
    height: auto;
  }

  .nav button {
    display: none;
  }

  .nav .icon {
    display: block;
  }

  .para2 {
    display: none;
  }

  .crossicon {
    display: block;
  }

  .par {
    display: block;
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -1.56px;
    color: #fd5d5d;
    margin: 0px 15px 0px 20px;
  }

  .betapara {
    display: block;
  }

  .parr {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #ffffff;
    margin: 10px 26px 0px 20px;
  }

  .form2 {
    margin-bottom: 48px;
    max-width: 340px;
    margin-top: 79px;
    padding: 0;
    position: relative;
    bottom: 0px;
  }

  .boxx {
    max-width: 225px;
    height: 45px;
    margin-left: 15px;
    padding-left: 6px;
    padding-right: 4px;
    padding-bottom: 3px;
  }

  .place {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
    margin-left: 8px;
  }

  .queue {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
    margin-top: 21px;
    margin-left: 15px;
  }

  .waitlist {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #adadad;
    margin-left: 15px;
    margin-right: 6px;
    margin-top: 5px;
  }

  .refferBox {
    display: none;
  }

  .refferBox2 {
    display: block;
    margin: 34px 15px 0px;
  }

  .referBox2 {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .forlineBox2 {
    width: 308.23px;
    border: 2px solid #fd5d5d;
  }

  .personalLink {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-top: 37px;
    margin-left: 15px;
    color: #ffffff;
  }

  .inputPersonalLink {
    box-sizing: border-box;
    width: 310px;
    height: 50px;
    border: none;
    margin-left: 15px;
    background: #2b2a2a;
    border-radius: 5px;
    color: white;
  }

  .linkinput {
    display: none;
  }

  .LinkBox2 {
    display: block;
  }

  .shareButton {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    margin-bottom: 44px;
  }

  .Twitter {
    width: 310px;
    margin-left: 17px;
    margin-right: 13px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #000000;
    justify-content: center;
    height: 40px;
  }

  .linkedin {
    box-sizing: border-box;
    width: 310px;
    height: 40px;
    margin-left: 17px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    justify-content: center;
    margin-top: 14px;
    background: #f5ff01;
    border-radius: 2px;
    padding: 10px 11px;
    height: 40px;
  }

  .refer {
    margin-top: 18px;
  }

  .line p {
    font-size: 15px;
  }

  .inputPersonalLink::placeholder {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #b8b9bb;
    padding-left: 16.53px;
  }
  .twitterMobile {
    margin-right: 8px;
  }
  .LinkedinMobile {
    margin-right: 8px;
  }
  .personalLinkagain {
    display: none;
  }
}
