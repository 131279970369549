@font-face {
  font-family:"Helvetica Neue LT bd Std" ;
  src: url(../public/fonts/HelveticaNeueLTStd-Bd.otf) format("truetype");
}
@font-face {
  font-family:"Helvetica Neue LT Std" ;
  src: url(../public/fonts/HelveticaNeueLTStd-Lt.otf) format("truetype");
}
@font-face {
  font-family:"Helvetica Neue LT rmn Std" ;
  src: url(../public/fonts/HelveticaNeueLTStd-Roman.otf) format("truetype");
}
@font-face {
  font-family:"B612 Mono bd" ;
  src: url(../public/fonts/B612/B612Mono-Bold.ttf) format("truetype");
}


/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #373737;
}

.contain {
  background-color: #373737;
  width: 100%;
  height: 100vh;
  padding-top: 54px;
}

.contain img {
  margin-left: 59px;

}

.nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.icon {
  margin-right: 34px;
  color: #FFFFFF;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 67% 33%;
  padding: 0 228px;
  margin-top: 470px;
  align-items: flex-start;
  justify-content: center;
}

.para1 {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 215px;
  line-height: 191px;
  display: flex;
  align-items: center;
  letter-spacing: -1.56px;
  color: #FD5D5D;
}

.para2 {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 61px;
  line-height: 62px;
  display: flex;
  align-items: center;
  letter-spacing: -0.48px;
  color: #F1F1EF;
  ;
}

.paragraph {
  width: 100%;
}

.paragraph2 {
  max-width: 1433px;
  margin-top: 48px;
}

.form {
  width: 100%;
  background-color: #000000;
  padding: 60px 58px;
  border-radius: 15px;
}

.input1 {
  color: white;
  width: 100%;
  height: 86px;
  position: relative;
  background: #2B2A2A;
  border-radius: 5px;
  border: none;
}

.input1:focus {
  background: #2A2A2A;
  border: 1px solid #FD5D5D;
  border-radius: 5px;
}

.form p {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  top: 11px;
  line-height: 82px;
  position: relative;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 49px;
  gap: 10px;
  position: relative;
  width: 100%;
  height: 86px;
  background: #F5FF01;
  border-radius: 2px;
  font-family: 'B612 Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 19px;
  color: #000000;

  margin-top: 104px;

  border: none;
}

.form button:hover {
  color: black;
  background: #F5FF01;

}

.paras {
  margin-right: 45px;
}

.lastpara {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;

  font-size: 28px;
  line-height: 38px;

  margin-top: 82px;
  color: #B8B9BB;
}



.nav button {
  background: #F5FF01;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 49px;
  gap: 10px;
  margin-right: 42px;
  border: none;
  font-family: 'B612 Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;

  color: #000000;

}

.boxx {
  display: flex;
  max-width: 400px;
  background-color:
    #FD5D5D;
  max-height: 100px;

  align-items: flex-end;
  padding-right: 17px;
  padding-left: 5px;
  border-radius: 0px 0px 5px 5px;
}

.form2 {
  width: 100%;
  background-color: #000000;
  padding: 0px 56px;
  height: auto;
  border-radius: 15px;
}

.place {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;

  font-size: 25px;

  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #000000;
}

.placeNumber {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #F1F1EF;
}

.queue {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #F1F1EF;
  margin-top: 43px;
}

.waitlist {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 41px;
  display: flex;
  align-items: center;
  color: #ADADAD;
  margin-top: 34px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
}

.line p {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.forline {
  border: 4px solid #FD5D5D;
}

::placeholder {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ADADAD;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 7px 9px;
}

.alone {
  margin-top: 55px;
}

.Twitter {
  width: 200px;
  padding: 10px 26px;
  background: #F5FF01;
  border-radius: 2px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  display: flex;
  flex: 1;
  align-items: center;
  color: #000000;
  height: 86px;
  border: none;
  justify-content: center;
  cursor: pointer;
}

.linkedin {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #000000;
  border: none;
  cursor: pointer;
  width: 200px;
  height: 86px;
  left: 1459px;
  top: 726px;
  background: #F5FF01;
  border-radius: 2px;
  padding: 11px 17px;
  margin-left: 10px;
}

.shareButton {
  display: flex;
  margin-top: 25px;
  margin-bottom: 46px;
}

input:focus {
  outline: none !important;
}

.error {
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  display: flex;
  align-items: center;
  color: #FD5D5D;
}

.iconform {
  display: block;
}

.earlyAcc {
  display: none;
}

.currentBeta {
  display: none;
}

.crossicon {
  display: none;
}

.refferBox2 {
  display: none;
}

.LinkBox2 {
  display: none
}

@media only screen and (max-width: 1920px) {



  .contain {
    background-color: #373737;
    width: 100%;
    height: 100vh;
    padding-top: 54px;
  }

  .contain img {
    margin-left: 59px;
  }

  .nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .icon {
    margin-right: 34px;
    color: #FFFFFF;
  }

  .content {
    width: 100%;
    display: flex;
    padding: 0 228px;
    margin-top: 232px;
    align-items: flex-start;
    justify-content: center;
  }

  .para1 {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 700;
    font-size: 128px;
    line-height: 110px;
    display: flex;
    align-items: center;
    letter-spacing: -1.56px;
    color: #FD5D5D;
  }

  .para2 {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: -0.48px;
    color: #F1F1EF;
    ;
  }

  .paragraph {
    max-width: 961px;
  }

  .paragraph2 {
    max-width: 844px;
    margin-top: 29px;
  }

  .form {
    max-width: 472px;
    background-color: #000000;
    padding: 30px 31px;
    border-radius: 15px;
  }

  .input1 {
    color: white;
    width: 100%;
    height: 50px;
    position: relative;
    background: #2B2A2A;
    border-radius: 5px;
    border: none;
  }

  .input1:focus {
    background: #2A2A2A;
    border: 1px solid #FD5D5D;
    border-radius: 5px;
  }

  .form p {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    top: 11px;
    line-height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }

  .form button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 49px;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 50px;
    background: #F5FF01;
    border-radius: 2px;
    font-family: 'B612 Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 66px;
    border: none;
  }

  .form button:hover {
    color: black;
    background: #F5FF01;

  }

  .paras {
    margin-right: 31px;
  }

  .lastpara {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 82px;
    color: #B8B9BB;
  }



  .nav button {
    background: #F5FF01;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 49px;
    gap: 10px;
    margin-right: 42px;
    border: none;
    font-family: 'B612 Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #000000;

  }

  .boxx {
    display: flex;
    max-width: 317px;
    background-color:
      #FD5D5D;
    max-height: 65px;
    align-items: flex-end;
    padding-right: 17px;
    padding-left: 5px;
    border-radius: 0px 0px 5px 5px;

  }

  .form2 {
    max-width: 472px;
    background-color: #000000;
    padding: 0px 31px;
    height: auto;
    border-radius: 15px;
  }

  .place {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #F1F1EF;
  }

  .queue {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #F1F1EF;
    margin-top: 15px;
  }

  .waitlist {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ADADAD;
    margin-top: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .line {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
  }

  .line p {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }

  .forline {
    border: 4px solid #FD5D5D;
  }

  ::placeholder {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 7px 9px;
  }

  .alone {
    margin-top: 55px;
  }

  .Twitter {
    width: 200px;
    padding: 10px 26px;
    background: #F5FF01;
    border-radius: 2px;
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    height: 40px;
    border: none;
    cursor: pointer;
  }

  .linkedin {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    border: none;
    cursor: pointer;
    width: 200px;
    max-height: 40px;
    left: 1459px;
    top: 726px;
    background: #F5FF01;
    border-radius: 2px;
    padding: 11px 17px;
    margin-left: 10px;
  }

  .shareButton {
    display: flex;
    margin-top: 10px;
    margin-bottom: 36px;
  }

  input:focus {
    outline: none !important;
  }

  .error {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 54px;
    display: flex;
    align-items: center;
    color: #FD5D5D;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }

  .crossicon {
    display: none;
  }

  .refferBox2 {
    display: none;
  }

  .LinkBox2 {
    display: none
  }
}

@media only screen and (max-width: 1500px) {
  .error {
    font-size: 15px;
    line-height: 40px;
  }

  .para1 {
    font-size: 77px;
    line-height: 0.9;
  }

  .para2 {
    font-size: 21px;
    line-height: 1.1;
  }

  .content {
    margin-top: 150px;
  }

  .paragraph2 {
    max-width: 456px;
    margin-top: 20px;
  }

  ::placeholder {

    font-size: 10px;

    padding: 5px 7px;
  }

  .form p {
    font-size: 11px;
    line-height: 49px;
    top: 14px;
  }

  .form {
    padding: 0px 23px;
    max-width: 349px;
  }

  .contain {
    padding-top: 28px;
  }

  .input1 {
    height: 32px;
  }

  .form button {
    margin-top: 32px;
    height: 32px;
    font-size: 10px;
    width: 100%;
    padding: 11px 49px;
  }

  .lastpara {
    margin-top: 26px;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 13px;
  }

  .par {
    font-size: 65px;
  }

  .par2 {}

  .parr {
    font-size: 20px;
    margin-top: 20px;
  }

  .place {
    font-size: 6px;
  }

  .placeNumber {
    font-size: 18px;
    line-height: 36px;

  }

  .boxx {
    max-width: 147px;

  }

  .queue {
    margin-top: 3px;
    font-size: 22px;
  }

  .waitlist {
    font-size: 12px;
    line-height: 1.2;
  }

  .form2 {
    max-width: 320px;
    padding: 0px 20px;
  }

  .line {
    margin-top: 5px;
  }

  .line p {
    font-size: 12px;
  }

  .alone {
    margin-top: 16px;
  }

  .Twitter {
    padding: 10px 11px;
    font-size: 10px;
  }

  .linkedin {
    font-size: 10px;
    padding: 1px 3px;
  }

  .forline {
    border: 1px solid #FD5D5D;
  }

  .shareButton {
    margin-bottom: 18px;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }

  .crossicon {
    display: none;
  }

  .refferBox2 {
    display: none;
  }

  .LinkBox2 {
    display: none
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form {
    margin-top: 50px;
  }

  .para1 {
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 0.9;
    display: flex;
    justify-content: center;
    color: #FD5D5D;
  }

  .para2 {
    font-size: 16px;
    line-height: 1.1;
  }

  .paras {}

  .paragraph2 {
    max-width: 347px;
    ;
    margin-top: 20px;
  }

  .form2 {
    max-width: 320px;
    background-color: #000000;
    padding: 0px 31px;
    height: auto;
    margin-top: 40px;
  }

  .content {
    width: 100%;
    display: flex;
    padding: 0 228px;
    margin-top: 132px;
  }

  .form button {

    width: 100%;

  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }

  .crossicon {
    display: none;
  }

  .refferBox2 {
    display: none;
  }

  .LinkBox2 {
    display: none
  }

}

@media only screen and (max-width: 1000px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 98px;
    margin-top: 132px;
  }

  .paras {
    text-align: center;
  }

  .paragraph2 {
    max-width: none;

  }

  .form button {}

  .form {
    max-width: 307px;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }

  .crossicon {
    display: none;
  }

  .refferBox2 {
    display: none;
  }

  .LinkBox2 {
    display: none
  }
}

@media screen and (max-width: 376px) {
  body {
    padding: 0;
    margin: 0;
  }

  .contain {
    padding: 0;
    margin: 0;
  }

  .contain img {
    margin: 20px 0px 0px 20px;
  }

  .icon {
    margin-top: 22.5px;
    margin-right: 19.5px;
  }

  .content {
    margin-top: 42.87px;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .para1 {
    display: none;
  }

  .earlyAcc {
    display: block;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 700;
    font-size: 47px;
    line-height: 48px;


    letter-spacing: -1.56px;

    color: #FD5D5D;
    margin: 0px 15px 0px 20px;
  }

  .paras {
    width: 100%;
    text-align: start;
    margin-right: 0px;
  }

  .paragraph {
    width: 100%;
  }

  .para2 {
    display: none;
  }

  .paragraph2 {
    margin: 0;
  }

  .currentBeta {
    display: block;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;



    color: #B8B9BB;
    margin-left: 20px;
  }

  .form {
    max-width: 340px;
    margin: 27px 17px 35px 18px;
    padding: 7px 20px 18px 20px;
    box-sizing: border-box;
  }

  .form p {
    top: 0;
  }

  .input1 {
    position: relative;
    max-width: 300px;
    height: 50px;
    bottom: 13px;
    background: #2B2A2A;
    border-radius: 5px;
  }

  .form button {
    width: 100%;
    height: 37px;
  }

  .lastpara {
    margin: 28px 0px 0px 0px;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;




    color: #B8B9BB;

  }

  .contain {
    height: auto;
  }

  .nav button {
    display: none;
  }

  .nav .icon {
    display: block;
  }

  .para2 {
    display: none;
  }

  .crossicon {
    display: block;
  }

  .par {
    display: block;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;


    letter-spacing: -1.56px;

    color: #FD5D5D;
    margin: 0px 15px 0px 20px;
  }

  .betapara {
    display: block;
  }

  .parr {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;

    color: #FFFFFF;
    margin: 10px 26px 0px 20px;
  }

  .form2 {
    margin-bottom: 48px;
    max-width: 340px;
    padding: 0;
  }

  .boxx {
    max-width: 225px;
    height: 45px;
    margin-left: 15px;
    padding-left: 6px;
    padding-right: 4px;
    padding-bottom: 3px;
  }

  .place {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;


    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;

    color: #000000;
  }

  .placeNumber {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;


    display: flex;
    align-items: center;

    color: #F1F1EF;

  }

  .queue {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;


    display: flex;
    align-items: center;

    color: #F1F1EF;
    margin-top: 21px;
    margin-left: 15px;
  }

  .waitlist {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;



    color: #ADADAD;
    margin-left: 15px;
    margin-top: 5px;
  }

  .refferBox {
    display: none;
  }

  .refferBox2 {
    display: block;
    margin: 34px 15px 0px;

  }

  .referBox2 {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;


    display: flex;
    align-items: center;

    color: #FFFFFF;

  }

  .forlineBox2 {
    width: 308.23px;


    border: 2px solid #FD5D5D;
  }

  .personalLink {
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    margin-top: 37px;
    margin-left: 15px;

    color: #FFFFFF;
  }

  .inputPersonalLink {
    box-sizing: border-box;
    width: 310px;
    height: 50px;
    border: none;
    margin-left: 15px;
    background: #2B2A2A;
    border-radius: 5px;
    color: white;
  }

  .linkinput {
    display: none;
  }

  .LinkBox2 {
    display: block
  }

  .shareButton {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }

  .Twitter {
    width: 310px;
    margin-left: 17px;
    margin-right: 13px;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;


    display: flex;


    color: #000000;
    justify-content: center;
  }

  .linkedin {
    box-sizing: border-box;


    width: 310px;
    height: 40px;
    margin-left: 17px;
    font-family: 'Helvetica Neue LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;


    display: flex;
    align-items: center;

    color: #000000;
    justify-content: center;
    margin-top: 14px;
    background: #F5FF01;
    border-radius: 2px;
    padding: 10px 11px;
  }

  .refer {
    margin-top: 18px;
  }

} */