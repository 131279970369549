* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #373737;
}

.contain {
  background-color: #373737;
  width: 100%;
  height: 100vh;
  padding-top: 54px;
}

.contain img {
  margin-left: 59px;
  height: 89px;
}

.login-form-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.icon {
  margin-right: 34px;
  color: #ffffff;
}

.login-form-content {
  width: 100%;
  display: grid;
  grid-template-columns: 67% 33%;
  padding: 0 228px;
  margin-top: 470px;
  align-items: flex-start;
  justify-content: center;
}

.login-form-para1 {
  font-family: "Helvetica Neue LT bd Std";
  font-style: normal;
  font-weight: 700;
  font-size: 215px;
  line-height: 191px;
  display: flex;
  align-items: center;
  letter-spacing: -1.56px;
  color: #fd5d5d;
}

.login-form-para2 {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 62px;
  display: flex;
  align-items: center;
  letter-spacing: -0.48px;
  color: #f1f1ef;
}

.login-form-paragraph {
  width: 100%;
}

.login-form-paragraph2 {
  max-width: 1433px;
  margin-top: 48px;
}

.form {
  width: 100%;
  background-color: #000000;
  padding: 60px 58px;
  border-radius: 15px;
  position: relative;
  bottom: 41px;
}

.login-form-input1 {
  color: white;
  width: 100%;
  height: 86px;
  position: relative;
  background: #2b2a2a;
  border-radius: 5px;
  border: none;
}

.login-form-input1:focus {
  background: #2a2a2a;
  border: 1px solid #fd5d5d;
  border-radius: 5px;
}

.form p {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  top: 11px;
  line-height: 82px;
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 49px;
  gap: 10px;
  position: relative;
  width: 100%;
  height: 86px;
  background: #f5ff01;
  border-radius: 2px;
  font-family: "B612 Mono bd";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 19px;
  color: #000000;
  margin-top: 104px;
  border: none;
}

.form button:hover {
  color: black;
  background: #f5ff01;
}

.login-form-paras {
  margin-right: 45px;
}

.lastpara {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 38px;
  margin-top: 82px;
  color: #b8b9bb;
}

/* 2nd page */
.login-form-input1::placeholder {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #b8b9bb;
}

input:focus {
  outline: none !important;
}

.error {
  font-family: "Helvetica Neue LT rmn Std";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  display: flex;
  align-items: center;
  color: #fd5d5d;
}

.iconform {
  display: block;
}

.earlyAcc {
  display: none;
}

.currentBeta {
  display: none;
}

@media only screen and (min-width: 1921px) and (max-width: 2500px) {
  .contain {
    background-color: #373737;
    width: 100%;
    height: 100vh;
    padding-top: 54px;
  }

  .contain img {
    margin-left: 59px;
    height: 89px;
  }

  .login-form-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .icon {
    margin-right: 34px;
    color: #ffffff;
  }

  .login-form-content {
    width: 100%;
    display: grid;
    grid-template-columns: 67% 33%;
    padding: 0 228px;
    margin-top: 186px;
    align-items: flex-start;
    justify-content: center;
  }

  .login-form-para1 {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 170px;
    line-height: 140px;
    display: flex;
    align-items: center;
    letter-spacing: -1.56px;
    color: #fd5d5d;
  }

  .login-form-para2 {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 49px;
    line-height: 62px;
    display: flex;
    align-items: center;
    letter-spacing: -0.48px;
    color: #f1f1ef;
  }

  .login-form-paragraph {
    width: 100%;
  }

  .login-form-paragraph2 {
    max-width: 1127px;
    margin-top: 48px;
  }

  .form {
    width: 100%;
    background-color: #000000;
    padding: 41px 35px;
    border-radius: 15px;
    position: relative;
    bottom: 41px;
  }

  .login-form-input1 {
    color: white;
    width: 100%;
    height: 86px;
    position: relative;
    background: #2b2a2a;
    border-radius: 5px;
    border: none;
  }

  .login-form-input1:focus {
    background: #2a2a2a;
    border: 1px solid #fd5d5d;
    border-radius: 5px;
  }

  .form p {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    top: 11px;
    line-height: 82px;
    position: relative;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .form button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 49px;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 86px;
    background: #f5ff01;
    border-radius: 2px;
    font-family: "B612 Mono bd";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 19px;
    color: #000000;
    margin-top: 57px;
    border: none;
  }

  .form button:hover {
    color: black;
    background: #f5ff01;
  }

  .login-form-paras {
    margin-right: 45px;
  }

  .lastpara {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    margin-top: 82px;
    color: #b8b9bb;
  }

  /* 2nd page */
  .login-form-input1::placeholder {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 24px;
    /* or 150% */

    display: flex;
    align-items: center;

    color: #b8b9bb;
  }

  input:focus {
    outline: none !important;
  }

  .error {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    display: flex;
    align-items: center;
    color: #fd5d5d;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }
}

@media only screen and (max-width: 1920px) {
  .contain {
    background-color: #373737;
    width: 100%;
    height: 100vh;
    padding-top: 54px;
  }

  .contain img {
    margin-left: 59px;
    height: 42.26px;
    width: 200px;
  }

  .login-form-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .icon {
    margin-right: 34px;
    color: #ffffff;
  }

  .login-form-content {
    width: 100%;
    display: flex;
    padding: 0 228px;
    margin-top: 232px;
    align-items: flex-start;
    justify-content: center;
  }

  .login-form-para1 {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 128px;
    line-height: 110px;
    display: flex;
    align-items: center;
    letter-spacing: -1.56px;
    color: #fd5d5d;
  }

  .login-form-para2 {
    /* font-family: "Helvetica Neue LT rmn Std"; ;
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: -0.48px;
    color: #F1F1EF;
    ; */
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    /* align-items: center; */
    /* letter-spacing: -0.48px; */
    letter-spacing: 0.1px;
    color: #f1f1ef;
  }

  .login-form-paragraph {
    max-width: 961px;
  }

  .login-form-paragraph2 {
    max-width: 883px;
    margin-top: 17px;
  }

  .form {
    max-width: 472px;
    background-color: #000000;
    padding: 30px 32px;
    border-radius: 15px;

    position: relative;
    bottom: 41px;
  }

  .login-form-input1 {
    color: white;
    width: 100%;
    height: 50px;
    position: relative;
    background: #2b2a2a;
    border-radius: 5px;
    border: none;
  }

  .login-form-input1:focus {
    background: #2a2a2a;
    border: 1px solid #fd5d5d;
    border-radius: 5px;
  }

  .form p {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    top: 11px;
    line-height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .form button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 49px;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 50px;
    background: #f5ff01;
    border-radius: 2px;
    font-family: "B612 Mono bd";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 66px;
    border: none;
  }

  .form button:hover {
    color: black;
    background: #f5ff01;
  }

  .login-form-paras {
    margin-right: 31px;
  }

  .lastpara {
    /* font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 82px;
    color: #B8B9BB; */
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    align-items: center;

    color: #b8b9bb;
  }

  /* 2nd page */
  .login-form-input1::placeholder {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #b8b9bb;
  }

  input:focus {
    outline: none !important;
  }

  .error {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #fd5d5d;
    margin-top: 4px;
  }


  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }
}

@media only screen and (min-width: 1499px) and (max-width: 1782px) {
  .login-form-content {
    margin-top: 124px;
  }
  .login-form-para1 {
    font-size: 95px;
    line-height: 76px;
  }
  .login-form-paragraph2 {
    max-width: 633px;
    margin-top: 24px;
  }
  .form {
    max-width: 389px;
    padding: 12px 27px;
  }
  .login-form-para2 {
    font-size: 27px;
    line-height: 40px;
  }
  .form p {
    font-size: 17px;
    top: 15px;
  }
  .form button {
    margin-top: 39px;
  }
  .lastpara {
    margin-top: 38px;
    font-size: 13px;
  }
  .error {
    font-size: 15px;
    line-height: 40px;
    font-size: 15px;
    margin-top: 4px;
    line-height: 22px;
  }

}

@media only screen and (max-width: 1500px) {
  .error {
    font-size: 15px;
    line-height: 40px;
    font-size: 15px;
    margin-top: 4px;
    line-height: 22px;
  }

  .login-form-para1 {
    font-size: 77px;
    line-height: 0.9;
  }

  .login-form-para2 {
    font-size: 21px;
    line-height: 1.1;
  }

  .login-form-content {
    margin-top: 150px;
  }

  .login-form-paragraph2 {
    max-width: 456px;
    margin-top: 20px;
  }

  .login-form-input1::placeholder {
    font-size: 10px;
    padding: 5px 7px;
  }

  .form p {
    font-size: 11px;
    line-height: 49px;
    top: 14px;
  }

  .form {
    padding: 0px 23px;
    max-width: 349px;
  }

  .contain {
    padding-top: 28px;
  }

  .login-form-input1 {
    height: 32px;
  }

  .form button {
    margin-top: 32px;
    height: 32px;
    font-size: 10px;
    width: 100%;
    padding: 11px 49px;
  }

  .lastpara {
    margin-top: 26px;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 13px;
  }

  .par {
    font-size: 65px;
  }

  .par2 {
  }

  .parr {
    font-size: 20px;
    margin-top: 20px;
  }

  .place {
    font-size: 6px;
  }

  .placeNumber {
    font-size: 18px;
    line-height: 36px;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1361px) {
  .login-form-para1 {
    font-size: 56px;
    line-height: 0.9;
  }
  .login-form-content {
    margin-top: 127px;
  }
  .login-form-para2 {
    font-size: 19px;
    line-height: 1.1;
  }

  /* Your CSS styles here */
}

@media only screen and (max-width: 1200px) {
  .login-form-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form {
    margin-top: 50px;
  }

  .login-form-para1 {
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 0.9;
    display: flex;
    justify-content: center;
    color: #fd5d5d;
  }

  .login-form-para2 {
    font-size: 16px;
    line-height: 1.1;
  }

  .login-form-paras {
  }

  .login-form-paragraph2 {
    max-width: 347px;
    margin-top: 20px;
  }

  .login-form-content {
    width: 100%;
    display: flex;
    padding: 0 228px;
    margin-top: 132px;
  }

  .form button {
    width: 100%;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .login-form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 98px;
    margin-top: 132px;
  }

  .login-form-paras {
    text-align: center;
  }

  .login-form-paragraph2 {
    max-width: none;
  }

  .form button {
  }

  .form {
    max-width: 307px;
  }

  .iconform {
    display: block;
  }

  .earlyAcc {
    display: none;
  }

  .currentBeta {
    display: none;
  }
}

/* @media only screen and (max-width: 500px) {
    .login-form-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 19px;
      margin-top: 58px;
    }
    .login-form-para1 {
      font-family: "Helvetica Neue LT Std";
      font-style: normal;
      font-weight: 700;
      font-size: 58px;
      line-height: 0.9;
      display: flex;
      justify-content: center;
      color: #FD5D5D;
  }
  .login-form-para2 {
    font-size: 19px;

}
.form {
  margin-top: 34px;
  max-width:400px;
}

    .login-form-paras {
      text-align: center;
    }
    .login-form-paragraph2  {
      max-width: none;
    }
    .form button {}
    .form {
      max-width: 307px;
    }
    .iconform {
      display: block;
    }
    .earlyAcc {
      display: none;
    }
    .currentBeta {
      display: none;
    }
    .lastpara {
      margin-top: 26px;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 43px;
  }
  } */
@media screen and (max-width: 500px) {
  body {
    padding: 0;
    margin: 0;
  }

  .contain {
    padding: 0;
    margin: 0;
  }

  .contain img {
    margin: 20px 0px 0px 20px;
    height: 21.13px;
    width: 100px;
  }

  .icon {
    margin-top: 22.5px;
    margin-right: 19.5px;
  }

  .login-form-content {
    margin-top: 42.87px;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .login-form-para1 {
    display: none;
  }

  .earlyAcc {
    display: block;
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 62px;
    line-height: 65px;
    letter-spacing: -1.56px;
    color: #fd5d5d;
    margin: 0px 15px 0px 20px;
  }

  .login-form-paras {
    width: 100%;
    text-align: start;
    margin-right: 0px;
  }

  .login-form-paragraph {
    width: 100%;
  }

  .login-form-para2 {
    display: none;
  }

  .login-form-paragraph2 {
    margin: 0;
  }

  .currentBeta {
    display: block;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 52px;
    color: #b8b9bb;
    margin-left: 20px;
  }

  .form {
    max-width: 353px;
    margin: 27px 17px 35px 18px;
    padding: 7px 20px 18px 20px;
    box-sizing: border-box;
    position: relative;
    bottom: 0px;
  }

  .form p {
    top: 0;
  }

  .login-form-input1 {
    position: relative;
    max-width: 100%;

    height: 50px;
    bottom: 13px;
    background: #2b2a2a;
    border-radius: 5px;
  }

  .form button {
    width: 100%;
    height: 37px;
  }

  .lastpara {
    margin: 28px 0px 0px 0px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #b8b9bb;
  }

  .contain {
    height: auto;
  }

  .login-form-nav button {
    display: none;
  }

  .login-form-nav .icon {
    display: block;
  }

  .login-form-para2 {
    display: none;
  }

  .par {
    display: block;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;

    letter-spacing: -1.56px;
    color: #fd5d5d;
    margin: 0px 15px 0px 20px;
  }

  .betapara {
    display: block;
  }

  .parr {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;

    color: #ffffff;
    margin: 10px 26px 0px 20px;
  }

  .place {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
  }

  .refferBox {
    display: none;
  }

  .referBox2 {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .personalLink {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    margin-top: 37px;
    margin-left: 15px;
    color: #ffffff;
  }

  .inputPersonalLink {
    box-sizing: border-box;
    width: 310px;
    height: 50px;
    border: none;
    margin-left: 15px;
    background: #2b2a2a;
    border-radius: 5px;
    color: white;
  }
}

@media screen and (max-width: 375px) {
  body {
    padding: 0;
    margin: 0;
  }

  .contain {
    padding: 0;
    margin: 0;
  }

  .contain img {
    margin: 20px 0px 0px 20px;
    height: 21.13px;
    width: 100px;
  }

  .icon {
    margin-top: 22.5px;
    margin-right: 19.5px;
  }

  .login-form-content {
    margin-top: 42.87px;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .login-form-para1 {
    display: none;
  }

  .earlyAcc {
    display: block;
    font-family: "Helvetica Neue LT bd Std";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -1.56px;
    color: #fd5d5d;
    margin: 0px 15px 0px 20px;
  }

  .login-form-paras {
    width: 100%;
    text-align: start;
    margin-right: 0px;
  }

  .login-form-paragraph {
    width: 100%;
  }

  .login-form-para2 {
    display: none;
  }

  .login-form-paragraph2 {
    margin: 0;
  }

  .currentBeta {
    display: block;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #b8b9bb;
    margin-left: 20px;
  }

  .form {
    max-width: 340px;
    margin: 27px 17px 35px 18px;
    padding: 7px 20px 18px 20px;
    box-sizing: border-box;
    position: relative;
    bottom: 0px;
  }

  .form p {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 54px;
    /* identical to box height, or 338% */

    display: flex;
    align-items: center;

    color: #ffffff;
    top: 0;
  }

  .login-form-input1 {
    position: relative;
    max-width: 300px;
    height: 50px;
    bottom: 13px;
    background: #2b2a2a;
    border-radius: 5px;
  }

  .form button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 49px;
    gap: 10px;

    background: #f5ff01;
    border-radius: 2px;
    width: 100%;
    height: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }

  .lastpara {
    margin: 28px 0px 0px 0px;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #b8b9bb;
  }

  .contain {
    height: auto;
  }

  .login-form-nav button {
    display: none;
  }

  .login-form-nav .icon {
    display: block;
  }

  .login-form-para2 {
    display: none;
  }

  .par {
    display: block;
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    /* or 100% */
    letter-spacing: -1.56px;
    color: #fd5d5d;
    margin: 0px 15px 0px 20px;
  }

  .betapara {
    display: block;
  }

  .parr {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    /* or 145% */
    color: #ffffff;
    margin: 10px 26px 0px 20px;
  }

  .place {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #000000;
  }

  .placeNumber {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #f1f1ef;
  }

  .refferBox {
    display: none;
  }

  .referBox2 {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .personalLink {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */
    margin-top: 37px;
    margin-left: 15px;
    color: #ffffff;
  }

  .inputPersonalLink {
    box-sizing: border-box;
    width: 310px;
    height: 50px;
    border: none;
    margin-left: 15px;
    background: #2b2a2a;
    border-radius: 5px;
    color: white;
  }

  .login-form-input1::placeholder {
    font-family: "Helvetica Neue LT rmn Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    color: #b8b9bb;
  }
}
